import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import { FC, useContext, useEffect, useRef, useState } from "react";
import Avatar from "react-avatar";
import { IconContext } from "react-icons";
import { BsQrCode } from "react-icons/bs";
import { FaBell, FaBook } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import ReactTimeago from "react-timeago";
import { ThemeContext } from "styled-components";
import { fetchEditAccount } from "../../services/editAccount";
import { postInvite } from "../../services/invite";
import { getAccount, removeAccount, removeToken, saveAccount, saveTheme, updateOrganisationId } from "../../services/localStorage";
import { fetchMyInvites } from "../../services/myInvites";
import DarkModeIcon from "../../svgs/DarkModeIcon";
import InfoIcon from "../../svgs/Legend";
import LightModeIcon from "../../svgs/LightModeIcon";
import MenuIcon from "../../svgs/MenuIcon";
import OpenPadlockIcon from "../../svgs/OpenPadlockIcon";
import OrgSettingsIcon from "../../svgs/OrgSettingsIcon";
import ProfileIcon from "../../svgs/ProfileIcon";
import SignOutIcon from "../../svgs/SignOutIcon";
import SwitchOrgIcon from "../../svgs/SwitchOrgIcon";
import { isBinaryMed } from "../../util/checkDomain";
import { getRole } from "../../util/checkRole";
import errToStr from "../../util/errToStr";
import ExternalLink from "../../util/externalLink";
import { getLogo } from "../../util/getLogo";
import Bold from "../Bold";
import { OutlineBtn, PrimaryBtn } from "../Buttons";
import FeedbackModal from "../FeedbackModal";
import { HorizontalLine } from "../HorizontalLine";
import LoadingContainer from "../LoadingContainer";
import SelectOrganisationModal from "../SelectOrganisationModal";
import StateContext from "../StateContext";
import Tooltip from "../Tooltip";
import { IconContainer, MenuButton, MenuExternalLink, MenuLink, MenuList } from "../Tooltip/styles";
import VerifyEmailModal from "../VerifyEmailModal";
import {
  Badge,
  BadgeNumber,
  Bar,
  DetailsContainer,
  Email,
  LogoContainer,
  MenuBtn,
  MenuContainer,
  Notification,
  NotificationBody,
  NotificationContainer,
  NotificationDate,
  NotificationFooter,
  NotificationIconContainer,
  NotificationImg,
  OrgAvatarContainer,
  OrgName,
  Organisation,
  OrganisationContainer,
  ProfileContainer,
  UserAvatarContainer,
  Username,
} from "./styles";

const TopNav: FC<any> = ({ enableNav, open, toggleOpen }) => {
  const { color, long_datetime } = useContext(ThemeContext);

  const { theme, setTheme } = useContext(StateContext);

  const menuRef = useRef<any>(null);
  const notificationsRef = useRef<any>(null);

  const location = useLocation();

  const [accountInfo, setAccountInfo] = useState<any>(getAccount());

  const [data, setData] = useState<any>([]);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const [orgDomainFlag, setOrgDomainFlag] = useState<boolean>(accountInfo.orgDomain !== undefined && !window.location.hostname.includes(accountInfo.orgDomain));
  const [verifyEmailModalOpen, setVerifyEmailModalOpen] = useState<any>(false);
  const [changeOrgModalOpen, setChangeOrgModalOpen] = useState<any>(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState<any>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  useEffect(() => {
    if (notificationsRef.current && orgDomainFlag) {
      notificationsRef.current.show();
    }
  }, [orgDomainFlag]);

  // listen for account info or theme updates and update the active theme
  useEffect(() => {
    document.addEventListener("accountinfoupdated", updateAccountInfo);

    return () => {
      document.removeEventListener("accountinfoupdated", updateAccountInfo);
    };
  }, []);

  useEffect(() => {
    fetchNotifications();
    closeUserMenu();
  }, [location]);

  useEffect(() => {
    const updatedFlag = accountInfo.orgDomain !== undefined && !window.location.hostname.includes(accountInfo.orgDomain);
    if (updatedFlag !== orgDomainFlag) {
      setOrgDomainFlag(updatedFlag);
    }
  }, [accountInfo]);

  const updateAccountInfo = () => {
    setAccountInfo(getAccount());
  };

  const fetchNotifications = () => {
    setDataLoading(true);
    setDataErr("");

    fetchMyInvites(source)
      .then((response) => {
        setData(response.sort((a: any, b: any) => b.invitedDate - a.invitedDate));
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const handleAcceptInvite = (inviteId: string, organisationId: string) => {
    const body = {
      action: "accept",
      inviteId,
    };

    setDataLoading(true);
    setDataErr("");

    postInvite(source, body)
      .then(() => {
        setData((prev: any) => prev.filter((row: any) => row.inviteId !== inviteId));
        setDataLoading(false);
        updateOrganisationId(organisationId);
        handleOrgChange();
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const handleOrgChange = () => {
    setDataLoading(true);
    setDataErr("");
    fetchEditAccount(source)
      .then((response) => {
        saveAccount(response);
        // queryCache.invalidateQueries(() => true, { refetchInactive: true });
        // queryCache.clear();
        // Only clear query params for map page as it may be confusing if filters/map position aren't reset on org change
        if (window.location.pathname === "/map") window.location.search = "";
        else window.location.reload();
        const accountInfoUpdatedEvent = new Event("accountinfoupdated");
        document.dispatchEvent(accountInfoUpdatedEvent);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const handleDeclineInvite = (inviteId: string) => {
    const body = {
      action: "cancel",
      inviteId,
    };

    setDataLoading(true);
    setDataErr("");

    postInvite(source, body)
      .then(() => {
        setData((prev: any) => prev.filter((row: any) => row.inviteId !== inviteId));
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const closeUserMenu = () => {
    if (menuRef.current) {
      menuRef.current.hide();
    }
  };

  return (
    <>
      <Bar open={open}>
        {enableNav ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip content={open ? "Collapse" : "Expand"} placement="right">
              <MenuBtn aria-label="menu" data-testid="menuBtn" onClick={() => toggleOpen(!open)}>
                <MenuContainer>
                  <MenuIcon />
                </MenuContainer>
              </MenuBtn>
            </Tooltip>
            <Link to="/">
              <LogoContainer>{getLogo(color, theme)}</LogoContainer>
            </Link>
          </div>
        ) : (
          <div></div>
        )}
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <Tooltip
            onCreate={(instance) => (menuRef.current = instance)}
            maxWidth="none"
            theme="binary-no-padding"
            content={
              <div>
                <ProfileContainer>
                  <Avatar name={accountInfo.fullName} src={accountInfo.userPhotoUrl} size="50px" round={true} />
                  <DetailsContainer>
                    <Username>
                      {accountInfo.fullName} ({getRole()})
                    </Username>
                    <Email>{accountInfo.email}</Email>
                  </DetailsContainer>
                </ProfileContainer>
                <HorizontalLine style={{ margin: "0" }} />
                {accountInfo.organisationId && (
                  <>
                    <OrganisationContainer>
                      <Avatar name={accountInfo.organisationName} src={accountInfo.orgPhotoUrl} size="50px" round={true} />
                      <DetailsContainer>
                        <Organisation>{accountInfo.organisationName}</Organisation>
                      </DetailsContainer>
                    </OrganisationContainer>
                    <HorizontalLine style={{ margin: "0" }} />
                  </>
                )}
                <MenuList style={{ padding: "0" }}>
                  {accountInfo.organisationId && accountInfo.organisationCount > 1 && !accountInfo.maintenance && (
                    <>
                      <MenuButton
                        onClick={() => {
                          closeUserMenu();
                          setChangeOrgModalOpen(true);
                        }}
                      >
                        <IconContainer>
                          <SwitchOrgIcon />
                        </IconContainer>
                        Switch Organisation...
                      </MenuButton>
                      <HorizontalLine style={{ margin: "0" }} />
                    </>
                  )}
                  {enableNav && (
                    <>
                      <MenuLink to="/settings">
                        <IconContainer>
                          <ProfileIcon />
                        </IconContainer>
                        Account
                      </MenuLink>
                      <MenuLink to="/organisation">
                        <IconContainer>
                          <OrgSettingsIcon />
                        </IconContainer>
                        Organisation
                      </MenuLink>
                    </>
                  )}
                  <MenuButton
                    onClick={() => {
                      closeUserMenu();
                      saveTheme(theme === "dark" ? "light" : "dark");
                      setTheme(theme === "dark" ? "light" : "dark");
                    }}
                  >
                    <IconContainer>{theme === "dark" ? <LightModeIcon /> : <DarkModeIcon />}</IconContainer>
                    {theme === "dark" ? "Toggle Light Mode" : "Toggle Dark Mode"}
                  </MenuButton>
                  <HorizontalLine style={{ margin: "0" }} />
                  {isBinaryMed() && (
                    <MenuExternalLink target="_blank" rel="noopener noreferrer" href="https://binarymed.notion.site">
                      <IconContainer>
                        <IconContext.Provider value={{ size: "16px" }}>
                          <FaBook />
                        </IconContext.Provider>
                      </IconContainer>
                      Documentation
                    </MenuExternalLink>
                  )}
                  <MenuButton
                    onClick={() => {
                      closeUserMenu();
                      setFeedbackModalOpen(true);
                    }}
                  >
                    <IconContainer>
                      <InfoIcon />
                    </IconContainer>
                    Feedback & Support
                  </MenuButton>
                  <MenuLink
                    to="/sign-in"
                    onClick={() => {
                      closeUserMenu();
                      removeToken();
                      removeAccount();
                    }}
                  >
                    <IconContainer>
                      <SignOutIcon />
                    </IconContainer>
                    Sign Out
                  </MenuLink>
                  {!accountInfo.emailVerified && (
                    <MenuButton
                      style={{ color: color.error[2] }}
                      onClick={() => {
                        closeUserMenu();
                        setVerifyEmailModalOpen(true);
                      }}
                    >
                      <IconContainer style={{ fill: color.error[2] }}>
                        <OpenPadlockIcon />
                      </IconContainer>
                      Verify Email
                    </MenuButton>
                  )}
                </MenuList>
              </div>
            }
            interactive={true}
            touch={true}
            appendTo={document.body}
            trigger="click"
            placement="bottom-end"
          >
            {accountInfo.organisationId ? (
              <OrgAvatarContainer>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <OrgName title={accountInfo.organisationName}>{accountInfo.organisationName}</OrgName>
                </div>
                <Avatar name={accountInfo.organisationName} size="28px" src={accountInfo.orgPhotoUrl} round={true} />
              </OrgAvatarContainer>
            ) : (
              <UserAvatarContainer>
                <Avatar name={accountInfo.fullName} size="28px" src={accountInfo.userPhotoUrl} round={true} />
              </UserAvatarContainer>
            )}
          </Tooltip>
          {enableNav && (
            <Tooltip
              onCreate={(instance) => (notificationsRef.current = instance)}
              onShow={() => {
                setDataErr("");
                fetchNotifications();
              }}
              maxWidth="none"
              theme="binary-no-padding"
              content={
                <LoadingContainer loading={dataLoading} err={dataErr}>
                  {data.length > 0 || orgDomainFlag ? (
                    <NotificationContainer>
                      {orgDomainFlag && (
                        <Notification key="wrong-domain">
                          <NotificationBody>
                            <div>
                              The <Bold title={accountInfo.organisationName}>{accountInfo.organisationName}</Bold> organisation works best at{" "}
                              <Bold>
                                <ExternalLink url={`https://${accountInfo.orgDomain}`} label={`https://${accountInfo.orgDomain}`} />
                              </Bold>
                              <br />
                              <br />
                              <span>Please update your bookmarks.</span>
                            </div>
                          </NotificationBody>
                        </Notification>
                      )}
                      {data.map((invite: any) => (
                        <Notification key={invite.inviteId}>
                          <NotificationBody>
                            <NotificationImg>
                              <Avatar name={invite.organisationName} src={invite.orgPhotoUrl} size="50px" round={true} />
                            </NotificationImg>
                            <div>
                              <Bold title={invite.invitedByEmail}>{invite.invitedByName}</Bold> has invited you to join <Bold>{invite.organisationName}</Bold>
                              <NotificationDate>
                                <ReactTimeago live={false} date={invite.invitedDate * 1000} title={moment.unix(invite.invitedDate).format(long_datetime)} />
                              </NotificationDate>
                            </div>
                          </NotificationBody>
                          <NotificationFooter>
                            <PrimaryBtn onClick={() => handleAcceptInvite(invite.inviteId, invite.organisationId)}>Accept</PrimaryBtn>
                            <OutlineBtn style={{ marginLeft: "12px" }} onClick={() => handleDeclineInvite(invite.inviteId)}>
                              Decline
                            </OutlineBtn>
                          </NotificationFooter>
                        </Notification>
                      ))}
                    </NotificationContainer>
                  ) : (
                    <div style={{ minWidth: "260px" }}>
                      <p style={{ padding: "36px 12px" }}>No Notifications</p>
                    </div>
                  )}
                </LoadingContainer>
              }
              interactive={true}
              touch={true}
              appendTo={document.body}
              trigger="click"
              placement="bottom-end"
            >
              <NotificationIconContainer>
                <div style={{ width: "20px", height: "20px", margin: "15px 10px" }}>
                  <IconContext.Provider value={{ size: "20px" }}>
                    <FaBell />
                  </IconContext.Provider>
                  {(data.length > 0 || orgDomainFlag) && (
                    <Badge>
                      <BadgeNumber>{data.length + orgDomainFlag < 100 ? data.length + orgDomainFlag : "99+"}</BadgeNumber>
                    </Badge>
                  )}
                </div>
              </NotificationIconContainer>
            </Tooltip>
          )}
          <Tooltip content="Scanner" trigger="mouseenter">
            <Link to="/scanner">
              <NotificationIconContainer>
                <div style={{ width: "20px", height: "20px", margin: "15px 10px" }}>
                  <IconContext.Provider value={{ size: "20px" }}>
                    <BsQrCode />
                  </IconContext.Provider>
                </div>
              </NotificationIconContainer>
            </Link>
          </Tooltip>
        </div>
      </Bar>
      {changeOrgModalOpen && <SelectOrganisationModal modalOpen={changeOrgModalOpen} setModalOpen={setChangeOrgModalOpen} />}
      {verifyEmailModalOpen && <VerifyEmailModal modalOpen={verifyEmailModalOpen} setModalOpen={setVerifyEmailModalOpen} />}
      {feedbackModalOpen && <FeedbackModal modalOpen={feedbackModalOpen} setModalOpen={setFeedbackModalOpen} />}
    </>
  );
};

export default TopNav;
