import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import { FC, useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";
import { fetchAutoComplete } from "../../services/autoComplete";
import { endManifest } from "../../services/manifests";
import calculateDistance from "../../util/calculateDistance";
import errToStr from "../../util/errToStr";
import { exists } from "../../util/formValidations";
import { WarningAlert } from "../Alerts";
import { GhostBtn, OutlineBtn, PrimaryBtn } from "../Buttons";
import { FormError } from "../FormComponents";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { ModalFormContainer } from "../Modal/styles";
import { AsyncSelect } from "../Select";

interface Coordinates {
  latitude: number;
  longitude: number;
}

interface Place {
  latitude: number;
  longitude: number;
  value: string;
  label: string;
}

const defaultManifest = {
  endPlace: null,
};

const EndManifestModel: FC<any> = ({ manifest, placeId, placeName, onSuccess, modalOpen, setModalOpen }) => {
  const { short_datetime } = useContext(ThemeContext);

  const [formData, setFormData] = useState<any>(defaultManifest);
  const [formErrors, setFormErrors] = useState<any>({});

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [endPlaceLoading, setEndPlaceLoading] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // Function to find the closest place within 1 km
  const findClosestPlace = (places: Place[], coords: Coordinates): Place | null => {
    try {
      // Sort the list of places by distance from the user's coordinates
      const sortedPlaces = places.sort((a, b) => {
        // Calculate Euclidean distances from the user's coordinates to each place
        const aDist = Math.sqrt(Math.pow(a.latitude - coords.latitude, 2) + Math.pow(a.longitude - coords.longitude, 2));
        const bDist = Math.sqrt(Math.pow(b.latitude - coords.latitude, 2) + Math.pow(b.longitude - coords.longitude, 2));

        // Return comparison result
        return aDist > bDist ? 1 : bDist > aDist ? -1 : 0;
      });

      // Check if the closest place is within 1 km of the user's coordinates
      if (sortedPlaces.length > 0) {
        const closestPlace = sortedPlaces[0];
        const distanceToClosestPlace = calculateDistance(closestPlace.latitude, closestPlace.longitude, coords.latitude, coords.longitude);

        if (distanceToClosestPlace !== null && distanceToClosestPlace <= 1) {
          return closestPlace;
        }
      }

      // Return null if no place is within 1 km of the user's coordinates
      return null;
    } catch (error) {
      console.error("An error occurred while finding the closest place within 1 km:", error);
      return null;
    }
  };

  // If no end place has been input, get the user's current location and find nearest place to set as endPlace.
  // If the user has disabled location services, use the placeId and placeName passed in as props.
  useEffect(() => {
    if (formData.endPlace == null) {
      setEndPlaceLoading(true);
      navigator.geolocation.getCurrentPosition(
        (pos: any) => {
          const { coords } = pos;
          fetchAutoComplete("places", "")
            .then((response) => {
              const closestPlace = findClosestPlace(response, coords);

              if (closestPlace) {
                setFormData((prev: any) => ({
                  ...prev,
                  endPlace: {
                    value: closestPlace.value,
                    label: closestPlace.label,
                  },
                }));
              } else {
                if (placeId !== undefined && placeName !== undefined) {
                  setFormData((prev: any) => ({
                    ...prev,
                    endPlace: {
                      value: placeId,
                      label: placeName,
                    },
                  }));
                }
              }

              setEndPlaceLoading(false);
            })
            .catch((err) => {
              if (!axios.isCancel(err)) {
                setSubmittingErr(errToStr(err));
                setEndPlaceLoading(false);
              }
            });
        },
        () => {
          if (placeId !== undefined && placeName !== undefined) {
            setFormData((prev: any) => ({
              ...prev,
              endPlace: {
                value: placeId,
                label: placeName,
              },
            }));
          }
          setEndPlaceLoading(false);
        }
      );
    }
  }, []);

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "endPlace":
          currValid = exists(name, value ? value.value : null, setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const handleEnd = () => {
    const valid = validateForm();

    if (valid) {
      setSubmitting(true);
      endManifest(source, manifest.id, formData.endPlace.value)
        .then(() => {
          if (onSuccess) onSuccess();
          setSubmittedMsg("Manifest Ended");
          setSubmitting(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setSubmittingErr(errToStr(err));
            setSubmitting(false);
          }
        });
    }
  };

  const handleSelectChange = (selected: any, action: any) => {
    setFormData((prev: any) => ({ ...prev, [action.name]: selected }));
    setFormErrors((prev: any) => ({ ...prev, [action.name]: undefined }));
  };

  // Auto-populates select input on search.
  const loadOptions = (inputName: string, inputValue: string, callback: any) => {
    fetchAutoComplete(inputName, inputValue).then((response) => {
      callback(response);
    });
  };

  const handleClose = () => {
    if (!submitting) setModalOpen(false);
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={handleEnd}
      onClose={handleClose}
      size="sm"
      title="End Manifest"
      success={submittedMsg}
      error={submittingErr}
      body={
        <LoadingContainer loading={submitting}>
          <div>
            <form noValidate onSubmit={(e) => e.preventDefault()}>
              {manifest.targetPlaceId != null && formData.endPlace != null && manifest.targetPlaceId !== formData.endPlace?.value && (
                <WarningAlert style={{ margin: "0 0 6px 0" }}>
                  The selected End Place isn’t the Target Destination for this manifest ({manifest.targetPlaceName})
                </WarningAlert>
              )}
              {manifest.deadlineTimeUnix != null && new Date(manifest.deadlineTimeUnix * 1000) <= new Date() && (
                <WarningAlert style={{ margin: "0 0 6px 0" }}>
                  The Manifest has passed its Deadline ({moment.unix(manifest.deadlineTimeUnix).format(short_datetime)})
                </WarningAlert>
              )}
              {manifest.status === "Started, WARNING" && <WarningAlert style={{ margin: "0 0 6px 0" }}>The Manifest is in WARNING status</WarningAlert>}
              <ModalFormContainer>
                <label>End Place</label>
                <AsyncSelect
                  name="endPlace"
                  defaultOptions={true}
                  isClearable={true}
                  isError={formErrors.endPlace}
                  value={formData.endPlace}
                  loadOptions={(inputValue: any, callback: any) => loadOptions("places", inputValue, callback)}
                  onChange={handleSelectChange}
                  isDisabled={endPlaceLoading}
                  isLoading={endPlaceLoading}
                  placeholder="Select..."
                />
                <FormError error={formErrors.endPlace}>{formErrors.endPlace}</FormError>
              </ModalFormContainer>
            </form>
          </div>
        </LoadingContainer>
      }
      footer={
        submittedMsg ? (
          <OutlineBtn onClick={handleClose}>Okay</OutlineBtn>
        ) : submittingErr ? (
          <OutlineBtn onClick={handleClose}>Okay</OutlineBtn>
        ) : (
          <>
            <GhostBtn onClick={handleClose}>Cancel</GhostBtn>
            <PrimaryBtn onClick={handleEnd}>End</PrimaryBtn>
          </>
        )
      }
    />
  );
};

export default EndManifestModel;
